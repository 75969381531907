import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Colors system leverages a purposeful set of color styles as the perfect starting point for any brand or project.
When it comes to color, contrast is critical for ensuring text is legible.`}</p>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p><strong parentName="p">{`Info`}</strong>{`: The android legion SDK does not provide colors for your tribe or squad, you have to override the default color according to your tribe or squad`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`in your composable function, can use value `}<inlineCode parentName="p">{`LegionTheme.colors.{color_token}`}</inlineCode>{` on the colors attribute widget. for example :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourComposable() {
    Text(
        text = stringResource(id = R.string.your_string),
        style = LegionTheme.typography.bodyLargeRegular,
        color = LegionTheme.colors.tertiary700
    )
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3>{`Primary`}</h3>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary700 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary600 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary500 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            primary25 = Color(0xFFFCFCFD),
            primary50 = Color(0xFFFCFCFD),
            primary100 = Color(0xFFFCFCFD),
            primary200 = Color(0xFFFCFCFD),
            primary300 = Color(0xFFFCFCFD),
            primary400 = Color(0xFFFCFCFD),
            primary500 = Color(0xFFFCFCFD),
            primary600 = Color(0xFFFCFCFD),
            primary700 = Color(0xFFFCFCFD),
            primary800 = Color(0xFFFCFCFD),
            primary900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Secondary`}</h3>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary700 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary600 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary500 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            secondary25 = Color(0xFFFCFCFD),
            secondary50 = Color(0xFFFCFCFD),
            secondary100 = Color(0xFFFCFCFD),
            secondary200 = Color(0xFFFCFCFD),
            secondary300 = Color(0xFFFCFCFD),
            secondary400 = Color(0xFFFCFCFD),
            secondary500 = Color(0xFFFCFCFD),
            secondary600 = Color(0xFFFCFCFD),
            secondary700 = Color(0xFFFCFCFD),
            secondary800 = Color(0xFFFCFCFD),
            secondary900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Tertiary`}</h3>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`900 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`800 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_700`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_500`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`400 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`300 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`200 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`100 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`50 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`25 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`white`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`black`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            tertiary25 = Color(0xFFFCFCFD),
            tertiary50 = Color(0xFFFCFCFD),
            tertiary100 = Color(0xFFFCFCFD),
            tertiary200 = Color(0xFFFCFCFD),
            tertiary300 = Color(0xFFFCFCFD),
            tertiary400 = Color(0xFFFCFCFD),
            tertiary500 = Color(0xFFFCFCFD),
            tertiary600 = Color(0xFFFCFCFD),
            tertiary700 = Color(0xFFFCFCFD),
            tertiary800 = Color(0xFFFCFCFD),
            tertiary900 = Color(0xFFFCFCFD),
            white = Color(0xFFFFFFFF),
            black = Color(0xFF000000)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Accent`}</h3>
    <p>{`Accent colours communicate important status to the user such as success, information,
warning or error. These colours should be used paired with text, an icon or both to
fulfill their purpose in the best way possible.`}</p>
    <br />
    <br />
    <h3>{`Success`}</h3>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation.
If you’re using green as your primary color, it can be helpful to introduce a different
hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            success25 = Color(0xFFFCFCFD),
            success50 = Color(0xFFFCFCFD),
            success100 = Color(0xFFFCFCFD),
            success200 = Color(0xFFFCFCFD),
            success300 = Color(0xFFFCFCFD),
            success400 = Color(0xFFFCFCFD),
            success500 = Color(0xFFFCFCFD),
            success600 = Color(0xFFFCFCFD),
            success700 = Color(0xFFFCFCFD),
            success800 = Color(0xFFFCFCFD),
            success900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Warning`}</h3>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”.
These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            warning25 = Color(0xFFFCFCFD),
            warning50 = Color(0xFFFCFCFD),
            warning100 = Color(0xFFFCFCFD),
            warning200 = Color(0xFFFCFCFD),
            warning300 = Color(0xFFFCFCFD),
            warning400 = Color(0xFFFCFCFD),
            warning500 = Color(0xFFFCFCFD),
            warning600 = Color(0xFFFCFCFD),
            warning700 = Color(0xFFFCFCFD),
            warning800 = Color(0xFFFCFCFD),
            warning900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Information`}</h3>
    <p>{`Infomation colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            info25 = Color(0xFFFCFCFD),
            info50 = Color(0xFFFCFCFD),
            info100 = Color(0xFFFCFCFD),
            info200 = Color(0xFFFCFCFD),
            info300 = Color(0xFFFCFCFD),
            info400 = Color(0xFFFCFCFD),
            info500 = Color(0xFFFCFCFD),
            info600 = Color(0xFFFCFCFD),
            info700 = Color(0xFFFCFCFD),
            info800 = Color(0xFFFCFCFD),
            info900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <br />
    <br />
    <h3>{`Error`}</h3>
    <p>{`Error colors are used across error states and in “destructive” actions.
They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_25`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Override Colors`}</h4>
    <p>{`Register color in your theme :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourAppTheme(content: @Composable () -> Unit) {
    LegionAppTheme(
        ...
        colorScheme = LgnColorScheme(
            error25 = Color(0xFFFCFCFD),
            error50 = Color(0xFFFCFCFD),
            error100 = Color(0xFFFCFCFD),
            error200 = Color(0xFFFCFCFD),
            error300 = Color(0xFFFCFCFD),
            error400 = Color(0xFFFCFCFD),
            error500 = Color(0xFFFCFCFD),
            error600 = Color(0xFFFCFCFD),
            error700 = Color(0xFFFCFCFD),
            error800 = Color(0xFFFCFCFD),
            error900 = Color(0xFFFCFCFD)
        ),
        ...
        darkTheme = false,
        content = content
    )
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/android-compose-color-system.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      